import React from "react";
export default function Education(){
    const education = window.RSK.education;
    return(
        <>
            <h3 className="page-sub-title">Education</h3>
            {
                Object.keys(education).map((list, i) =>
                    <div className="resume-timeline" key={i}>
                        <div className="timeline-left">
                            <h5 className="timeline-period">{education[list].session}</h5>
                        </div>
                        <div className="timeline-mid"></div>
                        <div className="timeline-right">
                            <h5 className="designation" dangerouslySetInnerHTML={{__html: education[list].degree}}></h5>
                            <p className="timeline-company">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><g><path d="M351.75 168a128 128 0 0 1-255.5 0z"></path><path d="M13.6 79.83l6.4 1.5v58.4c-7 4.19-12 11.5-12 20.27 0 8.4 4.6 15.4 11.1 19.71L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.27C51.4 175.42 56 168.42 56 160c0-8.79-5-16.1-12-20.29V87.12l52 12.53V136h256V99.65l82.3-19.82c18.2-4.41 18.2-27.1 0-31.5l-190.4-46a85.9 85.9 0 0 0-39.7 0L13.6 48.23c-18.1 4.39-18.1 27.19 0 31.6zm305.8 240.79L224 416l-95.4-95.4C57.1 323.73 0 382.23 0 454.42V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-9.6c0-72.17-57.1-130.67-128.6-133.78z"></path></g></svg>
                                {education[list].institute}
                            </p>
                        </div>
                    </div>
                )
            }
        </>
    )
}